import bgm from 'audio/bgm.mp3';
import bgImage from 'images/brand/intro-bg.jpg';

import type { Intro as MenuIntro } from 'components/molecules/Menu';

import type { ImageAlign, TourData } from 'utils/types/customization';

interface ModelConfig {
    tools: {
        info: boolean;
        infoDefault?: boolean;
        position: boolean;
        positionDefault?: boolean;
        highlight: boolean;
        tour: boolean;
        view: boolean;
        floors: boolean;
        share: boolean;
        screenshot: boolean;
        getLocation: boolean;
    };
    menu: boolean;
    music?: string;
    intro?: MenuIntro[];
    displayTourList?: string[];
    defaultTour: string;
    defaultTitle: string;
    tourData: {
        [key: string]: TourData;
    };
    fixedLink: {
        text: string;
        url: string;
    };
}

const modelConfig: ModelConfig = {
    tools: {
        info: true,
        infoDefault: true,
        position: true,
        positionDefault: false,
        highlight: true,
        tour: true,
        view: true,
        floors: true,
        share: true,
        screenshot: false,
        getLocation: false,
    },
    music: bgm,
    menu: true,
    intro: [
        {
            type: 'ZH',
            content: `我比較相信藝術是緣分、是一見鍾情，是心有靈犀。而不是要經過客觀的分析才會愛上它的媒妁之言。
            藝術無需言語，只要靜靜欣賞就行，所以我的畫作，不需要解釋，請慢慢品！`,
        },
    ],

    displayTourList: ['2013', '2014', '2017', '2022'],
    defaultTour: '2022',
    defaultTitle: 'Isabella Feng',
    tourData: {
        '2013': {
            text: '2013 人生初見',
            path: '2013',
            title: 'Isabella Feng | 2013 人生初見',
            cover: bgImage,

            modelId: 'G57TYcNtiiH',
            sr: '3.13,-.64',
            ss: '51',

            sweepId: 'd40db71386c8457bb444424324075434',
            position: { x: 1.5184246301651, y: 1.5287739038467407, z: 1.0995352268218994 },
            rotation: { x: 0.5849570807915304, y: -143.3402406753434 },
        },
        '2014': {
            text: '2014 正當美好',
            path: '2014',
            title: 'Isabella Feng | 2014 正當美好',
            cover: bgImage,

            modelId: 'dknMXXCEimX',

            sr: '-2.96,-1.55',
            ss: '67',

            sweepId: '84ba6ad9a6e74243b836c4b2411f2649',
            position: { x: 0.5544531345367432, y: 1.5683562755584717, z: 1.526590347290039 },
            rotation: { x: -0.22535072999063654, y: -91.22417727636275 },
        },
        '2017': {
            text: '2017 我就在這裡',
            path: '2017',
            title: 'Isabella Feng | 2017 我就在這裡',
            cover: bgImage,

            modelId: 'zMGAyvhsmQQ',
            sr: '-1.14,-1.57',
            ss: '1',

            sweepId: '005f2931f7754bcd8b30c9143bcbd176',
            position: { x: 0.27054014801979065, y: 1.5828633308410645, z: -0.05665815994143486 },
            rotation: { x: -0.28209883033121713, y: -89.87150887226134 },
        },
        '2022': {
            text: '2022 國泰世華藝術中心 雙人個展',
            path: '2022',
            title: 'Isabella Feng | 2022 國泰世華藝術中心 雙人個展',
            cover: bgImage,

            modelId: 'n67rdLYvTuN',
            sr: '-.02,-1.24',
            ss: '81',

            sweepId: '34d9bb6fae5146d0aa63391ebba1de1a',
            position: { x: -10.42442512512207, y: 1.5217938423156738, z: -4.363565444946289 },
            rotation: { x: -0.3657911023344702, y: -71.28138498367134 },
        },
    },
    fixedLink: {
        text: 'Isabella Feng 個人網站',
        url: 'https://www.isabellafeng.art/',
    },
};

export const imageAlignConfig: {
    default: ImageAlign;
    exceptions: {
        [key: string]: ImageAlign;
    };
} = {
    default: 'center',
    exceptions: {},
};

export default modelConfig;
